html, body {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    background-color: #000;
}

* {
    font-family: 'Montserrat', sans-serif;
}

@keyframes moveUpAndDown {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-5px); }
}

#home-header {
    height: 100vh;
    display: flex;
    flex-direction: column;
}

nav {
    position: fixed;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #000;
    padding: 20px;
    width: 100%;
    box-sizing: border-box;
    z-index: 1;
    top:0;

    svg {
        color: #fff;
        font-size: 24px;
        padding: 20px 0 20px 20px;
    }

    ul {
        position: absolute;
        background: #000;
        top: 108px;
        left: 0;
        width: 100%;
        padding: 20px;
        margin: 0;
        box-sizing: border-box;
        border-bottom: 1px solid #2e2e2e;
        border-top: 1px solid #2e2e2e;


        a {
            display: flex;
            flex-direction: row;
            gap: 10px;
            color: #fff;
            text-decoration: none;
            align-items: center;
            margin-bottom: 20px;

            &:last-child {
                margin-bottom: 0;
            }

            &.active {
                color: #ea9c06;
            }

            &.active > svg {
                color: #ea9c06;
            }
        }

        svg {
            padding: 0;
        }
    }

    #desktop-nav {
        display: none;
    }
}

#home-banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),url('https://images.unsplash.com/photo-1508700929628-666bc8bd84ea?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1740&q=80');
    background-size: cover;
    background-position: center;
    margin-top: 0;

    p {
        margin-bottom: 0;
        margin-top: 192px;
    }
}

.coaching section img {
    width: 100%;
    margin-bottom: 20px;
    border-radius: 5px;
}

#offer-banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),url('https://images.unsplash.com/photo-1521790797524-b2497295b8a0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1769&q=80');
    background-size: cover;
    background-position: center;
    height: 170px;
}

#coaching-banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),url('https://images.unsplash.com/photo-1505778276668-26b3ff7af103?q=80&w=2922&auto=format&fit=crop&ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D');
    background-size: cover;
    background-position: center;
    height: 170px;
}

#vision-banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),url('https://images.unsplash.com/photo-1427348693976-99e4aca06bb9?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80');
    background-size: cover;
    background-position: center;
    height: 170px;
}

#mission-banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),url('https://images.unsplash.com/photo-1476304884326-cd2c88572c5f?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1769&q=80');
    background-size: cover;
    background-position: center;
    height: 170px;
}

#strategy-banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),url('https://images.unsplash.com/photo-1602968407815-5963b28c66af?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1770&q=80');
    background-size: cover;
    background-position: center;
    height: 170px;
}

#contact-banner {
    background: linear-gradient(to bottom, rgba(0, 0, 0, 0.85), rgba(0, 0, 0, 0.85)),url('https://images.unsplash.com/photo-1423666639041-f56000c27a9a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=1774&q=80');
    background-size: cover;
    background-position: center;
    height: 170px;
}

header {
    display: flex;
    flex-direction: column;
    flex: 1;
    justify-content: center;
    align-items: center;
    margin-top: 108px;

    p {
        display: flex;
        flex-direction: column;
        justify-content: center;
        flex:5;
        color: #fff;
        font-size: 32px;
        text-align: center;
        text-transform: uppercase;
        justify-self: center;

        span {
            font-weight: bold;
            background: linear-gradient(to left, #EA9C06, #E7D49E);
            -webkit-background-clip: text;
            -webkit-text-fill-color: transparent;
        }
    }

    div {
        display: flex;
        padding: 50px;
        position: relative;
        flex: 1;
        align-items: center;
    }

    svg {
        color: #fff;
        font-size: 24px; 
        animation: moveUpAndDown 1s infinite;
    }
}

main {
    padding: 50px;
    background-color: #000;
    max-width: 384px;
    margin: 0 auto; 

    #home-buttons {
        display: flex;
        gap:10px;
        justify-content: space-between;
        margin-bottom: 50px;

        button {
            padding: 15px 20px;
            font-size: 16px;
            flex:1;
            background-color: #000;
            border: 1px solid #fff;
            color: #fff;
            border-radius: 5px;
            transition: all .3s; 
            cursor: pointer;

            &.active {
                background-color: #fff;
                color: #000;
            }

            &:hover {
                background-color: #fff;
                color: #000;
            }
        }
    }

    section {
        margin-bottom: 50px;
        h2 {
            margin-bottom: 10px;
        }

        p {
            margin-top: 0;
        }

        a {
            color: #fff;
        }
    }

    &#contact {
        text-align: center;
    }
}

h2 {
    margin-top: 0;
    text-transform: uppercase;
    text-align: center;
    color:#fff;
}

p {
    color: #fff;
    text-align: center;
}

#footer-button {
    display: flex;
    gap:5px;
    align-items: center;
    padding: 15px 20px;
    font-size: 16px;
    text-transform: capitalize;
    margin: 0 auto;
    border-width: 4px;
    color: #EA9C06;
    border: 1px solid #EA9C06;
    border-radius: 5px;
    background-color: #000;
    width: fit-content;
    text-decoration: none;
    transition: all .3s;

    &:hover {
        background-color: #EA9C06;
        color: #000;
    }

    &:hover > svg {
        color: #000;
    }

    svg {
        color: #EA9C06;
        transition: all .3s;
    }
}

@media screen and (min-width: 769px) {
    #home-header {
        header {
            p {
                font-size: 48px;
            }
        }
    }

    main {
        max-width: 768px;
        margin: 0 auto;

        &.container {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            max-width: 864px;
            gap:10px;
        }

        &.content {
            max-width: 384px;
        }

        section {
            flex:30%;
        }
    }

    #particulieren, #bedrijven {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;

        section {
            flex:50%;
        }
    }

    nav {
        #menu {
            display: none;
        }
        #desktop-nav {
            display: flex;
            gap: 25px;
            position: relative;
            background-color: transparent;
            top:0;
            border:none;
            justify-content: flex-end;

            a {
                margin-bottom: 0;
                gap:5px;
                transition: all .3s;

                svg {
                    transition: all .3s;
                }

                &:hover {
                    color: #EA9C06;
                }

                &:hover > svg {
                    color: #EA9C06;
                }
            }
        }
    }
}
